<template>
  <div class="entry_search_list_wrap">
    <div class="entry_search_list_box" v-for="i in 8" :key="`entrySearch${i}`" @click="confirm()">
      <div class="entry_search_list_thumbnail"></div>
      <div class="entry_search_list_bottom">
        <div class="entry_search_list_title">
          <span>공모전명이 길어지면 말줄임표를 사용합시다.</span>
        </div>
        <div class="entry_search_list_content">
          <div class="profile_img"></div>
          <div class="nick_name">
            <span>jwo9500</span>
          </div>
          <div class="vote_box">
            <div class="vote_img"></div>
            <div class="vote_count">
              <span>9,999+(99%)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
export default {
  name: "EntryListSearchLayout",
  mixins: [alertMixins],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    confirmCharge(){
      this.createConfirm({
        content : this.$t('confirm_charge'),
        confirmText : this.$t('yes'),
        cancelText : this.$t('cancel'),
        confirm: () => {
          this.resultCharge();
        },
        cancel: () => {

        }
      });
    },
    confirm() {
      this.createConfirm(
          {
            content: this.$t('entry_confirm_text'),
            confirmText: this.$t('yes'),
            cancelText: this.$t('cancel'),
            confirm: () => {

            },
            cancel: () => {

            }})
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>